import React from 'react';
import Image from 'gatsby-image';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  //display: flex;
  //position: absolut;
  //align-items: center;
  //width: 100%;
`;

const Overlay = styled.div`
  width: 100%;
  //text-align: center;
  //margin: 0px auto;
  //position: relative;
  //left: 0;
  //right: 0;
  //top: 0;
  //bottom: 0;
  //display: flex;
  //justify-content: center;
  //vertical-align: bottom;
  //text-align: right; 
  background-color: white;
  //border-color :red;
`;

const BgImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  //height: ${props => props.height || '100vh'};

  // Adjust image positioning (if image covers area with defined height) and add font-family for polyfill
  // & > img {
  //  object-fit: ${props => props.fit || 'cover'} !important;
  //  object-position: ${props => props.position || '50% 50%'} !important;
  //  font-family: 'object-fit: ${props => props.fit || 'cover'} !important; object-position: ${props => props.position || '50% 50%'} !important;'
  }
`;

class Hero extends React.Component {

  //<Overlay>
  //{children}
  //</Overlay>

  render() {
    //const { children } = this.props;
    return (
      <Container>
        <BgImage {...this.props} />
      </Container>
    );
  }
}

export default Hero;
